<template>
  <component :is="layout" :title="this.title">
    <router-view />
  </component>
  <Toast position="bottom-right" />
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import AuthLayout from "@/layouts/AuthLayout";
import Toast from "primevue/toast";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    AuthLayout,
    MainLayout,
    Toast,
  },
  computed: {
    title() {
      return this.$route.meta.title;
    },
    layout() {
      return this.$route.meta.layout + "-layout";
    },
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
    }),
  },
  methods: {
    initStorage() {
      this.me();
      this.getTopics("academy/topics", {});
      this.getPurchases();
      this.getNotifications();
      this.getTopNews();
      this.getRelatedNews();
      this.getSocialUrl();
    },
    ...mapActions(["getNotifications", "getSocialUrl"]),
    ...mapActions({
      getTopics: "academy/getTopics",
      getHelps: "academy/getHelps",
      getPurchases: "academy/getPurchases",
      getTopNews: "news/getTop",
      getRelatedNews: "news/getRelated",
      me: "auth/me",
    }),
  },
  mounted() {
    if (this.isAuthenticated) {
      this.initStorage();
    }
  },
  watch: {
    isAuthenticated: function (newVal) {
      if (newVal) {
        this.initStorage();
      }
    },
  },
};
</script>
