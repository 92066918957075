import userService from "@/services/userService";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? {
      status: { loggedIn: true },
      user,
      avatar: "",
      balance: {
        amount: 0,
        chain: 0,
        withdraw: 0,
        fund: 0,
        allTime: 0,
      },
    }
  : {
      status: { loggedIn: false },
      user: null,
      avatar: "",
      balance: {
        amount: 0,
        chain: 0,
        withdraw: 0,
        fund: 0,
        allTime: 0,
      },
    };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return userService.login(user).then(
        (response) => {
          commit("loginSuccess", response);
          return Promise.resolve(response);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },

    logout({ commit }) {
      userService.logout();
      commit("logout");
    },

    async me({ commit }) {
      await userService.me().then((response) => {
        commit("SET_ME", response.data);
        commit("SET_AVATAR", response.data.avatarUrl + "?" + Math.random());

        return Promise.resolve(response);
      });
    },

    async balance({ commit }) {
      await userService.balance().then((response) => {
        commit("SET_BALANCE", response.data);
        return Promise.resolve(response);
      });
    },

    register({ commit }, user) {
      return userService.register(user).then(
        (response) => {
          commit("registerSuccess");
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },

    async setAvatar({ commit }, filename) {
      commit("SET_AVATAR", filename + "?" + Math.random());
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      state.balance.amount = user.user.balance;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    SET_AVATAR: (state, item) => {
      state.avatar = item;
    },
    SET_ME: (state, item) => {
      state.me = item;
      state.balance.amount = item.balance;
    },
    SET_BALANCE: (state, item) => {
      state.balance = item;
    },
  },
  getters: {
    isAuthenticated: (state) => state.status.loggedIn,
    isAdmin: (state) => {
      return state.user && state.user.user.roles[0] === "ROLE_SUPER_ADMIN";
    },
    avatar: (state) => state.avatar,
    user: (state) => state.user,
    me: (state) => state.me,
    balance: (state) => {
      return {
        amount: new Intl.NumberFormat("ru-RU").format(state.balance.amount),
        chain: new Intl.NumberFormat("ru-RU").format(state.balance.chain),
        fund: new Intl.NumberFormat("ru-RU").format(state.balance.fund),
        allTime: new Intl.NumberFormat("ru-RU").format(state.balance.allTime),
        withdraw: new Intl.NumberFormat("ru-RU").format(state.balance.withdraw),
      };
    },
  },
};
